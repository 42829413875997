import React, { useState, useEffect, useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TextField from "@mui/material/TextField";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MdOutlineClose, MdShoppingBasket } from "react-icons/md";
import ModalPhoto from "../components/product/ModalPhoto";
import MetaTag from "../components/metaTag";
import {
  toggleButtonSx,
  toggleContainerSx,
  textFieldSx,
} from "../components/product/mui.style";
import {
  previewContainer,
  previewImage,
  image,
  settingsElement,
  toggle,
} from "../style/pages/frame.module.scss";
import {
  detail,
  settingsContainer,
  titleSettings,
  priceInfo,
  mobCloseBtn,
  closeBtn,
  costBundle,
  detailHeader,
  settings,
  settingsBlock,
  productInfo,
  settingsList,
  orderInfo,
  productContainer,
  productItem,
  delProduct,
  mobileProducts,
  hint,
  nubmer,
} from "../components/product/product.module.scss";

import { StoreContext } from "../context/store-context";

import frameData from "../data/frame.json";

function Frame() {
  const imageData = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "frame" }, name: { nin: ["main"] } }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          childImageSharp {
            id
            gatsbyImageData(
              breakpoints: [750, 1366, 1920]
              formats: [AUTO, AVIF, WEBP]
              quality: 85
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  `);

  const [selectedImg, setSelectedImg] = useState(null);

  const { store, setStore } = useContext(StoreContext);
  const [totalPrice, setTotalPrice] = useState(0);

  const [size, setSize] = useState(null);
  const [thickness, setThickness] = useState([]);

  const [quantity, setQuantity] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);

  const [mobileList, setMobileList] = useState(false);

  // Обновить состояние для диаметра
  const handleSize = (event, newAlignment) => {
    setSize(newAlignment);
    setThickness([]);
  };

  // Обновить состояние для толщины
  const handleThickness = (event, newAlignment) => {
    const innerText = event.target.innerText;
    setThickness([newAlignment, innerText]);
  };

  // Обновить состояние для количества подложек
  const handleQuantity = (event) => {
    if (Number(event.target.value) >= 0) {
      setQuantity(Number(event.target.value));
    } else return;
  };

  // Добавить первый набор подложек
  const addProduts = () => {
    const dataOrder = {
      size: size,
      thickness: thickness,
      quantity: quantity,
      totalAmount: totalAmount,
    };

    setTotalPrice(totalPrice + dataOrder.totalAmount);

    setStore({
      ...store,
      totalPrice: store.totalPrice + dataOrder.totalAmount,
      frame: {
        bundle: [...store.frame.bundle, dataOrder],
        totalPrice: store.frame.totalPrice + dataOrder.totalAmount,
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      substrates: { ...store.substrates },
      toppers: { ...store.toppers },
      screens: { ...store.screens },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      stand: { ...store.stand },
    });

    setSize(null);
    setThickness([]);

    setQuantity(0);
    setTotalAmount(0);
  };

  // Удалить набор подложек
  const deleteProduct = (index) => {
    // Массив всех наборов
    const arrBundle = [...store.frame.bundle];
    // Цена набора, который нужно удалить
    const deductibleAmount = store.frame.bundle[index].totalAmount;

    // Удаляем из массива наборов элемент с заданным index
    arrBundle.splice(index, 1);
    setStore({
      ...store,
      totalPrice: store.totalPrice - deductibleAmount,
      frame: {
        bundle: [...arrBundle],
        totalPrice: store.frame.totalPrice - deductibleAmount,
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      substrates: { ...store.substrates },
      toppers: { ...store.toppers },
      screens: { ...store.screens },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      stand: { ...store.stand },
    });
  };

  useEffect(() => {
    let q = quantity || 0;
    let c = thickness[0] || 0;

    setTotalAmount(q * c);
  }, [quantity, thickness]);

  return (
    <main className="mainpage">
      <MetaTag title="Рамка для нарезных конфет, шоколада, мармелада и ганаша | Wowmilota" />
      <motion.div
        layoutId="frameContainer"
        transition={{ type: "spring", bounce: 0.05, mass: 0.1 }}
        className={detail}
      >
        {selectedImg && (
          <ModalPhoto
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className={previewContainer}
        >
          {imageData?.allFile?.nodes.map((img, index) => {
            const { childImageSharp } = img;
            const src = getImage(img);

            return (
              <motion.div
                onClick={() => setSelectedImg(src)}
                onKeyDown={() => setSelectedImg(src)}
                className={previewImage}
                key={childImageSharp.id}
                whileHover={{ scale: 0.98 }}
              >
                <GatsbyImage
                  image={src}
                  alt="Фотография рамки для нарезных конфет, шоколада, мармелада и ганаша"
                  className={image}
                />
              </motion.div>
            );
          })}
        </motion.div>

        <div className={settingsContainer}>
          <div className={settings}>
            <div className={settingsBlock}>
              <div className={productInfo}>
                <div className={detailHeader}>
                  <h2>
                    Рамка для нарезных конфет, шоколада, мармелада и ганаша
                  </h2>
                </div>
                <p>
                  Форма для нарезных конфет, мармелада, шоколада предназначена
                  для формирования ровного слоя изделия. Для увеличения высоты
                  слоя необходимо использовать несколько рамок сложенных друг на
                  друга. Незаменимый помощник для создания конфет и мармелада.
                </p>
              </div>

              <h3 className={titleSettings}>Обязательные параметры</h3>

              <div className={settingsList}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: size
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Размер рамки (см)</h3>
                  <ToggleButtonGroup
                    size="medium"
                    value={size}
                    exclusive
                    onChange={handleSize}
                    sx={toggleContainerSx}
                  >
                    {frameData.map((item) => {
                      const { size } = item;

                      return (
                        <ToggleButton
                          value={size}
                          key={`size${size}`}
                          sx={toggleButtonSx}
                        >
                          {size}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      thickness[0] > 0
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Толщина (мм)</h3>
                  {size ? (
                    frameData
                      .filter((item) => {
                        if (item.size === size) {
                          return item;
                        } else {
                          return null;
                        }
                      })
                      .map((item) => {
                        const { three, five } = item;

                        return (
                          <ToggleButtonGroup
                            size="medium"
                            value={thickness}
                            exclusive
                            onChange={handleThickness}
                            sx={toggleContainerSx}
                            key={`thicknessToggle`}
                          >
                            {three && (
                              <ToggleButton value={three} sx={toggleButtonSx}>
                                3
                              </ToggleButton>
                            )}
                            {five && (
                              <ToggleButton value={five} sx={toggleButtonSx}>
                                5
                              </ToggleButton>
                            )}
                          </ToggleButtonGroup>
                        );
                      })
                  ) : (
                    <p>Сначала выберите диаметр</p>
                  )}
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      quantity > 0
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Количество</h3>
                  <div className={toggle}>
                    <TextField
                      id="acrylicQuantity"
                      variant="standard"
                      type="number"
                      onFocus={(e) => e.target.value === "0" && setQuantity("")}
                      onChange={handleQuantity}
                      value={quantity}
                      sx={textFieldSx}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={orderInfo}>
              <h3>Ваши наборы</h3>
              {store?.frame.totalPrice > 0 ? (
                <div className={productContainer}>
                  {store?.frame.bundle.map((item, index) => {
                    return (
                      <div key={`order_${index}`} className={productItem}>
                        <button
                          className={delProduct}
                          aria-label="Удалить"
                          onClick={() => deleteProduct(index)}
                        >
                          <MdOutlineClose color="white" />
                        </button>
                        <h3>Набор {index + 1}</h3>
                        <p>{item.size}</p>
                        <p>Толщина: {item.thickness[1]} мм</p>
                        <p>{item.quantity} шт.</p>
                        <p>{item.totalAmount} руб.</p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={hint}>
                  <p>
                    Пока у Вас нет наборов.
                    <br />
                    <br />
                    Когда Вы выберите все обязательные параметры, кнопка внизу
                    покажет стоимость набора.
                    <br /> <br />
                    Вы можете формировать любое количество наборов, просто
                    продолжайте выбирать параметры.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className={priceInfo}>
            <div className={mobileProducts}>
              <button onClick={() => setMobileList(!mobileList)}>
                <MdShoppingBasket color="white" />
                <p className={nubmer}>{store?.frame.bundle.length}</p>
              </button>
              {mobileList && (
                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={10}
                  grabCursor={true}
                  className={productContainer}
                >
                  {store?.frame.bundle.map((item, index) => {
                    return (
                      <SwiperSlide
                        key={`order_${index}`}
                        className={productItem}
                      >
                        <button
                          className="delProduct"
                          aria-label="Удалить"
                          onClick={() => deleteProduct(index)}
                        >
                          <MdOutlineClose color="white" />
                        </button>
                        <h3>Набор {index + 1}</h3>
                        <p>{item.size}</p>
                        <p>Толщина: {item.thickness[1]} мм</p>
                        <p>{item.quantity} шт.</p>
                        <p>{item.totalAmount} руб.</p>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </div>
            {totalAmount > 0 ? (
              <button className={costBundle} onClick={addProduts}>
                {totalAmount > 0
                  ? `Добавить / ${totalAmount} руб.`
                  : "Сформируйте набор"}
              </button>
            ) : (
              <p className={costBundle}>Сформируйте набор</p>
            )}
            <Link to="/" className={mobCloseBtn}>
              <MdOutlineClose color="white" />
            </Link>
            <Link to="/" className={closeBtn}>
              {store?.substrates.lhdf.bundle.length > 0
                ? `Сохранить и закрыть`
                : `закрыть`}
            </Link>
          </div>
        </div>
      </motion.div>
    </main>
  );
}

export default Frame;
